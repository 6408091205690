import { FilterMatchMode, FilterOperator } from "primevue/api";
const filters = {
  // mounted() {
  //   this.initColumnFilters();
  // },
  methods: {
    initGlobalFilter(filters) {
      if (filters) {
        filters.global = { value: null, matchMode: FilterMatchMode.CONTAINS };
      } else if (this.filters) {
        this.filters.global = { value: null, matchMode: FilterMatchMode.CONTAINS };
      }
    },
    initColumnFilters(dataTable, filters) {
      //let dataTable = this.$refs.dt;
      //let filters = this.filters;
      if (dataTable) {
        var column, fieldName, dataType;
        //For direct DataTable component
        if (Array.isArray(dataTable.columns)) {
          for (let i = 0; i < dataTable.columns.length; i++) {
            column = dataTable.columns[i];
            fieldName = column.props?.field;
            if (column.props && !column.props.filterField) {
              column.props.filterField = fieldName;
            }
            //If dataType is undefined using String by default
            dataType = column.props?.dataType || "String";
            if (fieldName) {
              switch (dataType) {
                case "date":
                  filters[fieldName] = {
                    operator: FilterOperator.AND,
                    constraints: [
                      { value: null, matchMode: FilterMatchMode.DATE_IS },
                    ],
                  };
                  break;
                case "numeric":
                  filters[fieldName] = {
                    operator: FilterOperator.AND,
                    constraints: [
                      { value: null, matchMode: FilterMatchMode.EQUALS },
                    ],
                  };
                  break;
                default:
                  filters[fieldName] = {
                    operator: FilterOperator.AND,
                    constraints: [
                      { value: null, matchMode: FilterMatchMode.CONTAINS },
                    ],
                  };
              }
            }
          }
        } else if (dataTable.meta && Array.isArray(dataTable.meta.columns)) {
          for (let i = 0; i < dataTable.meta.columns.length; i++) {
            column = dataTable.meta.columns[i];
            fieldName = column.name;
            //If dataType is undefined using String by default
            dataType = column.dataType || "String";
            if (fieldName) {
              switch (dataType) {
                case "date":
                  filters[fieldName] = {
                    operator: FilterOperator.AND,
                    constraints: [
                      { value: null, matchMode: FilterMatchMode.DATE_IS },
                    ],
                  };
                  break;
                case "numeric":
                  filters[fieldName] = {
                    operator: FilterOperator.AND,
                    constraints: [
                      { value: null, matchMode: FilterMatchMode.EQUALS },
                    ],
                  };
                  break;
                default:
                  filters[fieldName] = {
                    operator: FilterOperator.AND,
                    constraints: [
                      { value: null, matchMode: FilterMatchMode.CONTAINS },
                    ],
                  };
              }
            }
          }
        }
        this.filters = filters;
      }
    },
    clearFilters() {
      this.initGlobalFilter(this.filters);
      this.initColumnFilters(this.$refs.dt, this.filters);
      if (typeof this.onFilter == 'function') {
        this.onFilter();
      }
    },
  },
};

export default filters;
